import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { ServiceProvider } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import withOwnerParam from 'hoc/withOwnerParam'

const ServiceProviders = () => {
  const [providers, setProviders] = useState()
  const routeParams = useSelector((state) => state.routeParams)

  async function getProvidersData() {
    const _providers = await ServiceProvider.all()
    setProviders(_providers)
  }

  useEffect(() => {
    getProvidersData() // 🔄 Llama a la función que obtiene los datos de proveedores.

    return () => {
      setProviders([]) // 🧹 Limpia el estado cuando el componente se desmonta.
    }
  }, [routeParams.userId, routeParams.farmOwnerId])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2> Administrar proveedores de servicios </h2>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {providers ? (
            <ResourcesAsTable
              resources={providers}
              model={ServiceProvider}
              title='Proveedor de Servicios'
              refreshData={async () => await getProvidersData()}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
export default ServiceProviders
