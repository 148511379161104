import { Grid, Typography, Box, makeStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import Boxes from 'components/Dashboard/Boxes'
import React, { useState, useEffect } from 'react'
import { CattleSummAnalysis, dailyKpisData, ProductiveUnits } from 'utils/api/models'
import { useSelector } from 'react-redux'
import ProductiveUnitSelector from 'components/ProductiveUnitSelector'
import { KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  gridShape: {
    backgroundColor: 'white',
    height: 'auto',
    marginBottom: '20px',
    marginTop: '30px',
    textAlign: 'center',
  },
  gridShape2: {
    marginTop: '2.5%',
    paddingTop: '1%',
    paddingLeft: '1%',
    paddingRight: '1%',
  },
  noData: {
    fontWeight: '500',
    color: '#212F3D',
    fontFamily: 'Roboto',
    fontSize: '24px',
    lineHeight: '28,13',
    align: 'center',
    paddingTop: '5px',
  },
  dateBox1: {
    backgroundColor: 'white',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  txtdb: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    lineHeight: '28,13',
    fontWeight: '500',
    color: 'black',
    align: 'center',
    paddingTop: '5px',
  },
  year: {
    fontWeight: '500',
    color: '#5FAA00',
    fontFamily: 'Roboto',
    fontSize: '24px',
    lineHeight: '28,13',
    align: 'center',
    paddingTop: '5px',
  },
  graphBox: {
    height: '390px',
    backgroundColor: 'white',
  },
  tab: {
    backgroundColor: 'white',
    height: '50px',
    marginBottom: '20px',
    textAlign: 'center',
    marginRight: '8px',
  },
  tabtest: {
    fontFamily: 'Roboto',
    color: 'black',
    textTransform: 'unset',
    display: 'flex',
    fontSize: '20px',
  },
  resume: {
    fontFamily: 'Roboto',
    display: 'flex',
    fontSize: '20px',
    paddingLeft: '10px',
    color: '#305600',
    fontWeight: '400',
    marginLeft: '3%',
  },
  when: {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    color: 'black',
    marginLeft: '4.1%',
  },
  actualDateTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#305600',
    fontWeight: '300',
    marginRight: '15px',
  },
  realDate: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#000000',
    fontWeight: '400',
    marginRight: '15px',
  },
  sameLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sameLine2: {
    marginTop: '-47px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hr: {
    marginTop: '-10px',
    marginLeft: '4%',
    marginRight: '2%',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    height: '2px',
    border: 'none',
  },

  startDateContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  startDate: {
    width: '30%',
    maxWidth: 270,
    marginRight: '1%',
    paddingLeft: '4%',
  },
  csv: {
    marginTop: '20px',
    display: 'flex',
    marginLeft: '10%',
    marginRight: '1%',
  },
  pSelector: {
    position: 'relative',
    width: '30%',
    left: '4%',
    marginTop: '0.6%',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '3.5%',
    marginBottom: '20px',
    paddingTop: '30px',
  },
  inputBox: {
    margin: '-15px 10px 0 10px',
    width: '15%',
    height: '25px',

  },
  inputYearBox: {
    margin: '-15px 10px 0 10px',
    width: '8%',
  },
  inputLabel: {
    zIndex: '1',
    backgroundColor: 'white',
    left: '-47%',
  },
  monthlyUnitPicker: {
    margin: '-25px 0 9px 40px',
  },
  verticalBar: {
    borderLeft: '1px solid #000',
    height: '33px',
    margin: '0 20px 0 20px',
  },

}))

export default function Dashboard({
  ...props
}) {

  const classes2 = useStyles()


  //MONTHLY DATA
  const date = new Date()
  const [MonthlyInitialDate, setMonthlyInitialDate] = React.useState(date)
  const [MonthlyFinalDate, setMonthlyFinalDate] = React.useState(date)

  const handleMonthlyFinalDateChange = (date) => {
    setMonthlyFinalDate(date)
  }

  //DAILY DATA
  const [DailyInitialDate, setDailyInitialDate] = React.useState(date)
  const [DailyFinalDate, setDailyFinalDate] = React.useState(date)
  const handleDailyInicialDateChange = (date) => {
    setDailyInitialDate(date)
  }
  const handleDailyFinalDateChange = (date) => {
    setDailyFinalDate(date)
  }

  function formatDate(date, type) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    if (type === 'diario') {
      return `${day} ${month} ${year}`
    } else {
      return `${month} ${year}`
    }
  }

  const currentDate = new Date()
  const actualDateDay = formatDate(currentDate, 'diario')
  const actualDateMonth = formatDate(currentDate, 'mensual')

  const [selectedProductiveUnit, setSelectedProductiveUnit] = useState('')
  const handleProductiveUnitChange = (value) => {
    setSelectedProductiveUnit(value)
    setDefaultDates(value)
  }

  const [selectedMonthlyProductiveUnit, setSelectedMonthlyProductiveUnit] = useState('')

  const handleMonthlyProductiveUnitChange = (value) => {
    setSelectedMonthlyProductiveUnit(value)
  }

  //NUEVO SELECTOR DE MESES
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedEndYear, setSelectedEndYear] = useState(new Date().getFullYear())
  const [selectedRightMonth, setSelectedRightMonth] = useState(new Date().getMonth())
  const [selectedLeftMonth, setSelectedLeftMonth] = useState(new Date().getMonth())

  const generateMonthOptions = () => {
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ]
    return months.map((month, index) => ({ value: index, label: month }))
  }
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear()
    const options = []
    for (let i = 0; i <= 10; i++) {
      options.push(currentYear - i)
    }
    return options
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value)
  }

  const handleEndYearChange = (event) => {
    setSelectedEndYear(event.target.value)
  }

  const handleRightMonthChange = (event) => {
    setSelectedRightMonth(event.target.value)
  }

  const handleLeftMonthChange = (event) => {
    setSelectedLeftMonth(event.target.value)
  }

  useEffect(() => {
    const newFechaFinal = new Date(selectedEndYear, selectedRightMonth, 1)
    setMonthlyFinalDate(newFechaFinal)
  }, [selectedEndYear, selectedRightMonth])

  useEffect(() => {
    const newFechaInicial = new Date(selectedYear, selectedLeftMonth, 1)
    setMonthlyInitialDate(newFechaInicial)
  }, [selectedYear, selectedLeftMonth])

  // MONTHLY KPIS
  const [monthlyData, setMonthlyData] = useState([])
  const [monthlyFinalData, setMonthlyFinalData] = useState([])

  const routeParams = useSelector((state) => state.routeParams)
  const farmId = routeParams.farmId

  async function getMonthlyData() {
    const response = await CattleSummAnalysis.all()
    console.log('data2', response)
    const filteredData = response.filter(entry => {
      const entryDate = new Date(entry.Fecha)
      const initialDate = new Date(MonthlyInitialDate.getFullYear(), MonthlyInitialDate.getMonth())
      console.log('data', entry.farm_id, farmId)
      return entry.productive_unit_id === selectedMonthlyProductiveUnit &&
        entryDate.getFullYear() === initialDate.getFullYear() &&
        entryDate.getMonth() === initialDate.getMonth() &&
        entry.farm_id === farmId
    })
    setMonthlyData(filteredData)
  }

  async function getFinalMonthlyData() {
    const response = await CattleSummAnalysis.all()
    const filteredData = await response.filter(entry => {
      const entryDate = new Date(entry.Fecha)
      const finalDate = new Date(MonthlyFinalDate.getFullYear(), MonthlyFinalDate.getMonth())
      return entry.productive_unit_id === selectedMonthlyProductiveUnit &&
        entryDate.getFullYear() === finalDate.getFullYear() &&
        entryDate.getMonth() === finalDate.getMonth()
    })
    setMonthlyFinalData(filteredData)
  }

  //DAILY KPIS
  const [dailyData, setDailyData] = useState([])

  async function getDailyData() {
    try {
      const response = await dailyKpisData.all()
      const filteredData = response.filter(entry => {
        const entryDate = new Date(entry.Fecha) // Convertir la fecha del registro a objeto Date
        const initialDate = new Date(DailyInitialDate.getFullYear(), DailyInitialDate.getMonth(), DailyInitialDate.getDate())
        return entry.productive_unit === selectedProductiveUnit &&
          entryDate.getFullYear() === initialDate.getFullYear() &&
          entryDate.getMonth() === initialDate.getMonth() &&
          entryDate.getDate() === initialDate.getDate()
      })
      setDailyData(filteredData) // Actualiza el estado solo con los registros filtrados

    } catch (error) {
      console.error('Error al obtener los datos del resumen:', error)
    }
  }

  // Definir un nuevo estado para almacenar los datos de la fecha final
  const [dailyFinalData, setDailyFinalData] = useState([])

  // Función para obtener los datos de la fecha final
  async function getDailyFinalData() {
    try {
      const response = await dailyKpisData.all()
      const filteredData = response.filter(entry => {
        const entryDate = new Date(entry.Fecha)
        return entry.productive_unit === selectedProductiveUnit &&
          entryDate.getDate() === DailyFinalDate.getDate() &&
          entryDate.getMonth() === DailyFinalDate.getMonth() &&
          entryDate.getFullYear() === DailyFinalDate.getFullYear()
      })
      setDailyFinalData(filteredData)
    } catch (error) {
      console.error('Error al obtener los datos de la fecha final:', error)
    }
  }

  function calculateDeltaForDatum(initialValue, finalValue) {

    // Verifica si los valores son numéricos
    const initialValueNum = parseFloat(initialValue)
    const finalValueNum = parseFloat(finalValue)

    // Calcula el delta solo si ambos valores son numéricos
    if (!isNaN(initialValueNum) && !isNaN(finalValueNum)) {
      const delta = initialValueNum - finalValueNum
      return delta % 1 === 0 ? delta : delta.toFixed(2)
    }
    return '-'
  }

  const colorMapping = {
    'Leche total (L)': 'verde',
    'Producción por vaca (L)': 'verde',
    'Vacas en leche (Nº)': 'verde',
    'Medicion a camión (cm)': 'verde',
    'Medicion a estanque (cm)': 'verde',
    'Diferencia de medida estanque-camión (cm)': 'verde',
  }

  const monthlyColorMapping = {
    'Avance Pastoreo': 'verde',
    'Concentrado/Vaca/Dia': 'verde',
    'Fecha': 'verde',
    'Muertes': 'rojo',
    'Mastitis': 'rojo',
    'Metritis': 'rojo',
    'Grasa láctea': 'verde',
    'Proteina láctea': 'verde',
    'Rotación (días)': 'verde',
    'Superficie pastoreo (Has)': 'verde',
    'Vacas totales': 'verde',
    'Leche total (L)': 'verde',
  }

  async function setDefaultDates(productiveUnitId) {
    try {
      const response = await dailyKpisData.all()
      const filteredData = response.filter(entry => entry.productive_unit === productiveUnitId)

      if (filteredData.length >= 2) {
        const sortedData = filteredData.sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha))
        const latestDate = new Date(sortedData[0].Fecha)
        const secondLatestDate = new Date(sortedData[1].Fecha)
        setDailyInitialDate(secondLatestDate)
        setDailyFinalDate(latestDate)
      } else if (filteredData.length === 1) {
        const latestDate = new Date(filteredData[0].Fecha)
        setDailyInitialDate(latestDate)
        setDailyFinalDate(latestDate)
      }

    } catch (error) {
      console.error('Error al obtener las fechas por defecto:', error)
    }
  }



  useEffect(() => {
    async function fetchData() {
      if (!selectedProductiveUnit) {
        const productiveUnits = await ProductiveUnits.all()
        if (productiveUnits.length > 0) {
          const firstUnit = productiveUnits[0].id
          setSelectedProductiveUnit(firstUnit)
          setDefaultDates(firstUnit)
        }
      }
    }
    fetchData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId])


  useEffect(() => {
    async function fetchMonthlyData() {
      if (!selectedMonthlyProductiveUnit) {
        const productiveUnits = await ProductiveUnits.all()
        if (productiveUnits.length > 0) {
          setSelectedMonthlyProductiveUnit(productiveUnits[0].id) // Selecciona la primera unidad disponible
        }
      }
    }

    fetchMonthlyData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId])

  useEffect(() => {
    if (selectedProductiveUnit) {
      getDailyData()
      getDailyFinalData()
    }
  }, [selectedProductiveUnit, DailyInitialDate, DailyFinalDate])

  useEffect(() => {
    if (selectedMonthlyProductiveUnit) {
      getMonthlyData()
      getFinalMonthlyData()
    }
  }, [selectedMonthlyProductiveUnit, MonthlyInitialDate, MonthlyFinalDate])


  useEffect(() => {
    getDailyFinalData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId, DailyFinalDate, selectedProductiveUnit])


  useEffect(() => {
    getDailyData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId, DailyInitialDate, selectedProductiveUnit])


  useEffect(() => {
    getMonthlyData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId, MonthlyInitialDate, selectedMonthlyProductiveUnit])


  useEffect(() => {
    getFinalMonthlyData()
  }, [routeParams.userId, routeParams.farmOwnerId, routeParams.farmId, MonthlyFinalDate, selectedMonthlyProductiveUnit])

  return (
    <React.Fragment>
      <Grid container spacing={4}>

        {/* CAJA KPIs DIARIO */}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box className={classes2.gridShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>
            <div className={classes2.sameLine}>
              <p className={classes2.resume}>Resumen</p>
              <p className={classes2.actualDateTitle}>Día Actual:</p>
            </div>
            <div className={classes2.sameLine2}>
              <p className={classes2.when} >Diario</p>
              <p className={classes2.realDate}>{actualDateDay}</p>
            </div>
            <hr className={classes2.hr} />
            <div className={classes2.startDateContainer}>
              <div className={classes2.startDate}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Dia seleccionado'
                  value={DailyInitialDate}
                  onChange={handleDailyInicialDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>

              <div className={classes2.startDate}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Dia a comparar'
                  value={DailyFinalDate}
                  onChange={handleDailyFinalDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
              <div className={classes2.pSelector}>
                <ProductiveUnitSelector
                  handleProductiveUnitChange={handleProductiveUnitChange}
                  farmId={farmId}
                  selectedProductiveUnit={selectedProductiveUnit}
                />
              </div>
              <div className={classes2.csv}>
              </div>
            </div>

            <Grid container spacing={2} className={classes2.gridShape2}>

              {dailyData?.length ? (
                dailyData.map((entry, index) => {
                  const DailyfinalData = dailyFinalData[index]
                  return Object.entries(entry)
                    .filter(([name]) => name !== 'Fecha' && name !== 'productive_unit')
                    .map(([name, value]) => {
                      if (DailyfinalData && DailyfinalData[name] !== undefined) {
                        const delta = calculateDeltaForDatum(value, DailyfinalData[name])
                        const color = colorMapping[name] || ''

                        return (
                          <Grid key={`${index}-${name}`} item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Boxes
                              name={name}
                              category={'planilla de leche diaria'}
                              value={value}
                              delta={delta}
                              color={color}
                            />
                          </Grid>
                        )
                      } else {
                        // Si no existe un registro correspondiente en DailyfinalData, no hacemos la comparación ni calculamos el delta
                        return (
                          <Grid key={`${index}-${name}`} item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Boxes
                              name={name}
                              category={'planilla de leche diaria'}
                              value={value}
                            />
                          </Grid>
                        )
                      }
                    })
                }).flat() // aplana la matriz de matrices devuelta por map
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <Box
                    className={classes2.gridShape}
                    borderRadius='8px'
                    boxShadow='0px 0px 4px 3px #00000040'
                  >
                    <Typography className={classes2.txtdb}>
                      <span className={classes2.noData}>No hay datos</span>
                    </Typography>
                  </Box>
                </Grid>
              )}

            </Grid>
          </Box>
        </Grid>

        {/* CAJA KPIs MENSUALES */}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box className={classes2.gridShape} borderRadius='8px' boxShadow='0px 0px 4px 3px #00000040'>

            <div className={classes2.sameLine}>
              <p className={classes2.resume}>Resumen</p>
              <p className={classes2.actualDateTitle}>Mes Actual:</p>
            </div>
            <div className={classes2.sameLine2}>
              <p className={classes2.when}>Mensual</p>
              <p className={classes2.realDate}>{actualDateMonth}</p>
            </div>
            <hr className={classes2.hr} />

            <Box className={classes2.inputContainer}>

              {/* Selector de Años */}
              <FormControl className={classes2.inputYearBox}>
                <InputLabel variant="subtitle1">Mes</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {generateYearOptions().map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Selector de Meses Izquierdo */}
              <FormControl className={classes2.inputBox}>
                <InputLabel variant="subtitle1" className={classes2.inputLabel}>seleccionado:</InputLabel>
                <Select
                  value={selectedLeftMonth}
                  onChange={handleLeftMonthChange}
                >
                  {generateMonthOptions().map((leftMonth) => (
                    <MenuItem key={leftMonth.value} value={leftMonth.value}>{leftMonth.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <hr className={classes2.verticalBar} />

              {/* Selector de Meses Derecho */}
              <FormControl className={classes2.inputYearBox}>
                <InputLabel variant="subtitle1">Mes</InputLabel>
                <Select
                  value={selectedEndYear}
                  onChange={handleEndYearChange}
                >
                  {generateYearOptions().map((endYear) => (
                    <MenuItem key={endYear} value={endYear}>{endYear}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes2.inputBox}>
                <InputLabel className={classes2.inputLabel}>a comparar:</InputLabel>
                <Select
                  value={selectedRightMonth}
                  onChange={handleRightMonthChange}
                >
                  {generateMonthOptions().map((rightMonth) => (
                    <MenuItem key={rightMonth.value} value={rightMonth.value}>{rightMonth.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes2.monthlyUnitPicker}>
                <ProductiveUnitSelector
                  handleProductiveUnitChange={handleMonthlyProductiveUnitChange}
                  farmId={farmId}
                  selectedProductiveUnit={selectedMonthlyProductiveUnit}
                />
              </div>
            </Box>

            <Grid container spacing={2} className={classes2.gridShape2}>

              {monthlyData?.length ? (
                monthlyData.map((entry, index) => {
                  const MonthlyfinalData = monthlyFinalData[index]
                  return Object.entries(entry)
                    .filter(([name]) => name !== 'Fecha' && name !== 'productive_unit_id')
                    .map(([name, value]) => {
                      if (MonthlyfinalData && MonthlyfinalData[name] !== undefined) {
                        const delta = calculateDeltaForDatum(value, MonthlyfinalData[name])
                        const color = monthlyColorMapping[name] || ''
                        return (
                          <Grid key={`${index}-${name}`} item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Boxes
                              name={name}
                              category={'planilla de leche diaria'}
                              value={value}
                              delta={delta}
                              color={color}
                            />
                          </Grid>
                        )
                      } else {
                        return (
                          <Grid key={`${index}-${name}`} item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Boxes
                              name={name}
                              category={'planilla de leche mensual'}
                              value={value}
                            />
                          </Grid>
                        )
                      }
                    })

                }).flat() // aplana la matriz de matrices devuelta por map
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <Box
                    className={classes2.gridShape}
                    borderRadius='8px'
                    boxShadow='0px 0px 4px 3px #00000040'
                  >
                    <Typography className={classes2.txtdb}>
                      <span className={classes2.noData}>No hay datos</span>
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}