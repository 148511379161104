import AppBreadcrumbs from 'components/AppBreadcrumbs'
import React, { useState, useEffect, useRef } from 'react'
import {
  Toolbar,
  AppBar,
  Fab,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'
import Snackbar from 'utils/Snackbar'
import useParams from 'hooks/useParams'
import Logo from 'images/logo-verde.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  isotipo: {
    height: '35px',
    paddingRight: '10px',
    cursor: 'pointer',
  },
  logo: {
    height: '30px',
    width: '80px',
    cursor: 'pointer',
  },
  appBar: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
  },
  userContainer: {
    right: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userButton: {
    alignSelf: 'center',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
}))

const TopBar = () => {
  const user = useSelector((state) => state.user)
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const routeParams = useParams()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  // Cierra el menú automáticamente al cambiar de ruta
  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    history.push('/iniciar-sesion')
    dispatch(actions.unsetUser())
    Snackbar.toast('Se cerró la sesión', 'success')
  }

  const navigateTo = (path) => (event) => {
    history.push(path)
    handleClose(event)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color='secondary' className={classes.appBar}>
        <Box display='flex'>
          <Toolbar>
            <img
              src={Logo}
              className={classes.logo}
              onClick={() => history.push('/')}
              alt='logotipo'
            />
          </Toolbar>
          <AppBreadcrumbs />
        </Box>

        <Box p={1} className={classes.userContainer}>
          <i>{user.username}</i>

          <Box px={2}>
            <Fab
              className={classes.userButton}
              size='small'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleToggle}
              color='primary'
              ref={anchorRef}
            >
              <PersonIcon />
            </Fab>
          </Box>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement='bottom-end'
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id='menu-list-grow'>
                      {user.is_super_admin && (
                        <MenuItem onClick={navigateTo('/administracion-cuentas')}>
                          Administrar Cuentas
                        </MenuItem>
                      )}
                      {(user.is_admin || user.is_super_admin) && (
                        <>
                          <MenuItem
                            onClick={navigateTo(
                              `/${routeParams.farmOwnerId}/administracion-usuarios`,
                            )}
                          >
                            Administrar Usuarios
                          </MenuItem>
                          <MenuItem
                            onClick={navigateTo(
                              `/${routeParams.farmOwnerId}/administracion-campos`,
                            )}
                          >
                            Administrar Campos
                          </MenuItem>
                          <MenuItem
                            onClick={navigateTo(
                              `/${routeParams.farmOwnerId}/administracion-empresas`,
                            )}
                          >
                            Administrar Empresas
                          </MenuItem>
                          <MenuItem
                            onClick={navigateTo(
                              `/${routeParams.farmOwnerId}/unidades-productivas`,
                            )}
                          >
                            Administrar Unidades Productivas
                          </MenuItem>
                          <MenuItem
                            onClick={navigateTo(
                              `/${routeParams.farmOwnerId}/administracion-proveedores`,
                            )}
                          >
                            Administrar Proveedores
                          </MenuItem>
                        </>
                      )}
                      <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </AppBar>
    </React.Fragment>
  )
}

export default TopBar
