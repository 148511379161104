import React, { useState, useEffect } from 'react'
import { Grid, Paper, Box, Tabs, Tab, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { Business } from 'utils/api/models'
import { Df_business } from 'utils/api/models'
import ResourcesAsTable from './ResourcesAsTable'
import withOwnerParam from 'hoc/withOwnerParam'
import * as actions from 'actions'


const Businesses = () => {
  const [businessesData, setBusinessesData] = useState([])
  const [dfBusinessesData, setDfBusinessesData] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  const routeParams = useSelector((state) => state.routeParams)
  const dispatch = useDispatch()

  useEffect(() => {
    getBusinessData()
    getDfBusinessData()

    return () => {
      setBusinessesData([])
      setDfBusinessesData([])
    }
  }, [routeParams.userId, routeParams.farmOwnerId])

  async function getBusinessData() {
    try {
      const businesses = await Business.all()
      setBusinessesData(businesses)
      dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
    } catch (error) {
      console.error('Error fetching business data:', error)
    }
  }

  async function getDfBusinessData() {
    try {
      const dfBusinesses = await Df_business.all()
      setDfBusinessesData(dfBusinesses)
      dispatch(actions.breadcrumbResourcesUpdated({ farmOwnerId: true }))
    } catch (error) {
      console.error('Error fetching DF business data:', error)
    }
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            padding='10px'
          >
            <h2>Administrar empresas</h2>
          </Box>

          <Box width='100%' marginBottom='10px'>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              centered
              style={{ width: '100%' }}
            >
              <Tab label="SII" style={{ width: '50%' }} />
              <Tab label="Defontana" style={{ width: '50%' }} />
            </Tabs>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
          height='80vh'
        >
          {activeTab === 0 ? (
            <ResourcesAsTable
              resources={businessesData}
              model={Business}
              title="Empresa SII"
              refreshData={getBusinessData}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
              businessSelectionEnabled={true}
            />
          ) : (
            <ResourcesAsTable
              resources={dfBusinessesData}
              model={Df_business}
              title="Empresa Defontana"
              refreshData={getDfBusinessData}
              setSelected={(id) => console.log(id)}
              parentSelections={[]}
              businessSelectionEnabled={true}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Businesses