import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Breadcrumbs, Link, TextField, withStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { Business, FarmOwner, User, Farm } from 'utils/api/models'
import { getRedirectPath } from 'utils/common'

const AlbaBreadcrumbs = withStyles({
  root: {
    padding: '18px',
    fontStyle: 'oblique',
  },
})(Breadcrumbs)

const YEAR_LOWER_LIMIT = 2009
const currentYear = new Date().getFullYear() + 1
const optionsYear = _(_.range(currentYear, YEAR_LOWER_LIMIT))
  .map((y) => ({ id: y }))
  .value()

const routeMeta = {
  userId: { name: 'Usuario', reduxKey: 'users', model: User },
  farmOwnerId: {
    name: 'Propietario',
    reduxKey: 'farmOwners',
    model: FarmOwner,
  },
  year: {
    name: 'Año',
    options: optionsYear,
    model: {
      customName: (r) => r.id,
    },
  },
  businessId: {
    name: 'Empresa',
    reduxKey: 'businesses',
    model: Business,
  },
  farmId: {
    name: 'Campo',
    reduxKey: 'farms',
    model: Farm,
  },
}

const AppBreadcrumbs = (props) => {
  const path = useSelector(({ path }) => path)
  const state = useSelector((state) => state)
  const history = useHistory()
  const location = useLocation()
  const inputRef = React.useRef();
  const [navChangedToAbout, setNavChangedToAbout] = React.useState(false)

  /*console.log(path)

  
    /*React.useEffect(() => {
      if(path === '/:farmOwnerId/:businessId/finanzas/compras/:year' || path === '/:farmOwnerId/:businessId/finanzas/ventas/:year') {
        const unlisten = history.listen(() => {
          window.location.reload();
        });
        return () => {
          unlisten();
        };
      }
    }, [path]);
  */



  return (
    <AlbaBreadcrumbs aria-label='breadcrumb' key={location.pathname}>
      {path !== '*' &&
        _(_.trimStart(path, '/'))
          .split('/')
          .map((p) => {
            if (_.startsWith(p, ':')) {
              const autoCompleteKey = _.trimStart(p, ':')
              const meta = routeMeta[autoCompleteKey]
              const options = meta.reduxKey
                ? state[meta.reduxKey]
                : meta.options
              const value =
                _(options).find({ id: state.routeParams[autoCompleteKey] }) ||
                {}

              if (value.id && !navChangedToAbout) {
                history.push(
                  getRedirectPath(
                    path,
                    location.pathname,
                    p,
                    value.id || meta.model.customName(value),
                  ),
                  console.log('se excuto: ', value.id),
                )
                setNavChangedToAbout(true)
              }

              return (
                <Autocomplete
                  key={p}
                  size='small'
                  ref={inputRef}
                  options={options}
                  getOptionLabel={(option) =>
                    meta.model && meta.model.customName
                      ? String(meta.model.customName(option))
                      : option.name || 'Seleccione...'
                  }
                  style={{
                    width: 230,
                    display: autoCompleteKey === 'year' && ['/visitas-prediales', '/ventas', '/compras', 'estado-resultado', 'estado-resultado-mensual'].some(route => location.pathname.includes(route)) ? 'none' : 'block',
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${_.capitalize(meta?.name)}`}
                      variant='outlined'
                      role='search'
                    />
                  )}
                  onChange={(_e, value) => {
                    console.log('value2: ', value)
                    if (value) {
                      history.push(
                        getRedirectPath(
                          path,
                          location.pathname,
                          p,
                          value.id || meta.model.customName(value),
                        ),
                      )
                    }
                  }}
                  value={value}
                />
              )
            } else {
              return (
                <Link color='inherit' key={p}>
                  {_(p).split('-').map(_.capitalize).value().join(' ')}
                </Link>
              )
            }
          })
          .value()}
    </AlbaBreadcrumbs>
  )
}

export default AppBreadcrumbs
