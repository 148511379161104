import React, { useState, useEffect } from 'react'
import { makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { ProductiveUnits } from 'utils/api/models'

const useStyles = makeStyles((theme) => ({
    productiveSelector: {
        marginTop: '10px',
        marginLeft: '20px',
        width: '180px',
    },
}))

export default function ProductiveUnitSelector({ farmId, handleProductiveUnitChange }) {
    const classes = useStyles()
    const [productiveUnits, setProductiveUnits] = useState([])
    const [selectedUnit, setSelectedUnit] = useState('')

    useEffect(() => {
        async function fetchProductiveUnits() {
            try {
                const response = await ProductiveUnits.all()
                const unitsData = response.data
                const filteredUnits = unitsData.filter(unit => unit.farm_id === farmId)

                setProductiveUnits(filteredUnits)

                if (filteredUnits.length > 0) {
                    setSelectedUnit(filteredUnits[0].id)
                    handleProductiveUnitChange(filteredUnits[0].id)
                }
            } catch (error) {
                console.error('Error fetching units:', error)
            }
        }

        fetchProductiveUnits()
    }, [farmId])


    const handleUnitChange = (event) => {
        const selectedUnitId = event.target.value
        setSelectedUnit(selectedUnitId)
        handleProductiveUnitChange(selectedUnitId)
    }

    return (
        <FormControl className={classes.productiveSelector}>
            <InputLabel id="productive-unit-select-label">Unidad Productiva</InputLabel>
            <Select
                labelId="productive-unit-select-label"
                id="productive-unit-select"
                value={selectedUnit || ''} // Si no hay ninguna unidad seleccionada, evitar errores con ''
                onChange={handleUnitChange}
            >

                {productiveUnits.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                        {unit.productive_unit_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

