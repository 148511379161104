import React from 'react'
import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'
import { Box, Typography } from '@material-ui/core'
import ResourcesModalForm from 'components/ResourcesModalForm'
import { formatISO } from 'date-fns'
import { Alert } from '@material-ui/lab'

// Rutas
const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/accounting/businesses/`
const DF_SCRAPPER_LOCATION = `${BASE_URL}/api/farm_owners/:farm_owner_id/accounting/defontana_accounts/:id/run_scraper
` // ✅ Asegúrate que esta ruta exista en tu backend

export const resourceKey = 'df_business'
export const collectionKey = 'df_businesses'

/*
// ✅ ScrapperRun para ejecución manual del Scraper Defontana
function ScrapperRun({ resource }) {
    const model = {
        fields: [
            { name: 'Fecha inicio', field: 'desde', type: 'date' },
            { name: 'Fecha fin', field: 'hasta', type: 'date' },
        ],
        create: (params) => {
            const requestBody = {
                desde: formatISO(params.desde, { representation: 'date' }),
                hasta: formatISO(params.hasta, { representation: 'date' }),
            }

            return axios
                .post(DF_SCRAPPER_LOCATION, requestBody)
                .catch((e) => {
                    console.error('Error al ejecutar Scrapper DF:', e.response || e)
                    throw e.response
                })
        },
    }

    return resource?.id ? (
        <Box py={1}>
            <Typography variant='h2'>Obtener DTEs desde Defontana</Typography>
            <Alert severity='info'>
                Presione el botón e indique el periodo requerido. Luego de unos minutos
                podrá encontrar los DTE en el módulo de finanzas.
            </Alert>
            <Box py={1}>
                <ResourcesModalForm
                    resources={[]}
                    parentSelections={[]}
                    setSelected={() => null}
                    refreshData={() => null}
                    model={model}
                    noContainer
                    title='Ejecución'
                />
            </Box>
        </Box>
    ) : null
}
*/

export const fields = [
    { name: 'Nombre', field: 'name' },
    { name: 'Usuario', field: 'df_user' },
    { name: 'ID Cliente', field: 'df_client_id' },
    { name: 'ID empresa', field: 'df_business_id' },
    { name: 'Clave', field: 'df_password', only: 'create' },
    // {
    //     name: 'Obtención de DTE',
    //     field: 'dte',
    //     only: 'create',
    //     Component: ScrapperRun, // ✅ Integramos el ScrapperRun en el formulario de creación
    // },
]

export const create = async (business) => {
    business.provider = 'defontana'
    console.log('Datos antes de enviar (DFcreate):', business)
    return axios
        .post(RESOURCE_LOCATION, {
            business,
        })
        .then(dataGetter)
        .catch((e) => {
            console.error('Error en create:', e.response)
            throw e.response
        })
}

export const all = () => {
    console.log('Iniciando la solicitud a:', RESOURCE_LOCATION)

    return axios
        .get(RESOURCE_LOCATION)
        .then((response) => {
            const data = response.data.data
            const filteredData = data.filter((item) => item.provider === 'defontana')
            return filteredData
        })
        .catch((error) => {
            console.error('Error capturado en el bloque catch:', error)
            throw error
        })
}

export const edit = (business_id, business) => {
    business.provider = 'defontana'

    console.log('Datos a enviar:', { business_id, business })

    return axios
        .put(`${RESOURCE_LOCATION}/${business_id}`, {
            business,
        })
        .then(dataGetter)
        .catch((error) => {
            console.error('Error en edit:', error.response || error.message)
            throw error
        })
}

export const remove = (df_business_id) =>
    axios.delete(`${RESOURCE_LOCATION}/${df_business_id}`).then(dataGetter)
