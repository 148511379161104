import axios from 'axios'
import { BASE_URL, dataGetter } from 'utils/api'

const RESOURCE_LOCATION = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/productive_units/`
const RESOURCE_LOCATION2 = `${BASE_URL}/api/farm_owners/:farmOwnerId/land/productive_units_by_farms`

export const ProductiveUnits = [
  {
    name: 'Unidad Productiva',
    field: 'productive_unit_name',
    only: 'create',
    value: '',
  },
]

export const fields = [...ProductiveUnits]

export const all = () =>
  axios
    .get(RESOURCE_LOCATION)
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error) => {
      console.error('Mal', error)
      throw error
    })

export const byFarmIds = (farms_ids) =>
  axios
    .get(RESOURCE_LOCATION2, {
      params: {
        farms_ids,
      },
    })
    .then((response) => {
      const data = response.data
      return data
    })
    .catch((error) => {
      console.error('Mal', error)
      throw error
    })

export const create = async (data) => {
  try {
    const response = await axios.post(RESOURCE_LOCATION, data)
    return response.data
  } catch (error) {
    console.error('Error creating data via API:', error)
    throw error
  }
}

export const remove = (id) =>
  axios.delete(`${RESOURCE_LOCATION}/${id}`).then(dataGetter)
